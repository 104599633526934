<template>
  <div>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
      <div id="page-content">
        <div class="row mt-4" style="margin-left: 1%; margin-right: 1%">
          <div class="col-sm-12 col-lg-6" style="margin-top: 2%">
            <div class="white-box org mb-2">
              <div class="no-org no-cirlce">
                {{ onlineAdmissionList.length }}
              </div>
              <p class="widgettitle m-0">
                <span>No. of Schools Applied</span>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6" style="margin-top: 2%">
            <div class="white-box blu mb-2">
              <div class="no-blu no-cirlce">
                {{ studentInformation.length }}
              </div>
              <p class="widgettitle m-0">
                <span>Admission is completed</span>
              </p>
            </div>
          </div>
        </div>
        
        <div
          class="row mt-2"
          v-if="studentInformation.length > 0"
          style="margin-left: 1%; margin-right: 1%"
        >
          <div
            class="col-lg-12"
            v-for="(data, index) in studentInformation"
            :key="index"
          >
            <div class="widjet">
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" style="padding-left: 12px"
                    ><i class="fas fa-child"></i
                  ></span>
                  <h1>My Kids</h1>
                </div>
                <div class="mt-1">
                  <!-- <button class="btn round lblue tooltipt" @click="viewStudentProfileAndPayemnt(data._id)" >
                      <i class="far fa-list-alt"></i><span class="tooltiptext">Fee Payment</span>
                  </button> -->
                </div>
              </div>
              <div class="widjetcontent">
                <div class="kidswrapper p-3">
                  <div class="row">
                    <div class="col-12">
                      <div class="kids-details-box mb-3">
                        <div
                          class="has-float-label"
                          
                        >
                          <div class="schlogo">
                            <img
                              v-if="data.image && data.image.path"
                              :src="data.image.path"
                              alt="Avatar"
                              class="mt-3"
                              width="110"
                              height="110"
                            />
                             <div class="white-box" v-else>
                              <div class="no-blu no-cirlce">
                                {{ data.firstName ? data.firstName.charAt(0) : "" }}{{ data.lastName ? data.lastName.charAt(0) : "" }}
                              </div>
                             </div>
                            
                            <label for="ptmbnid"
                              >Student Photo<span class="required">*</span></label
                            >
                         
                          </div>
                        </div>
                        
                        <div class="item-content table-responsive">
                          <table class="table text-nowrap">
                            <tbody>
                              <tr>
                                <td>Name:</td>
                                <td v-if="data.type == 'Contact'">
                                  <a
                                    href="javascript:void(0);"
                                    @click.prevent="
                                      viewStudentProfileAndPayemnt(data._id)
                                    "
                                    >{{ data.firstName + " " + data.lastName }}
                                  </a>
                                </td>
                                <td v-else>
                                  <a
                                    href="javascript:void(0);"
                                    @click.prevent="
                                      viewStudentFreeSchool(data._id)
                                    "
                                    >{{ data.firstName + " " + data.lastName }}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>School Name:</td>
                                <td><span style="display:inline-block;white-space:break-spaces">{{ data.school.accountName }}</span></td>
                              </tr>
                              <tr>
                                <td>Gender:</td>
                                <td>{{ data.gender }}</td>
                              </tr>
                              <tr>
                                <td>DOB:</td>
                                <td>{{ data.dob | dateFormat }}</td>
                              </tr>
                              <tr>
                                <td>Academic Year:</td>
                                <td>
                                  {{
                                    data.academicYear.from +
                                    " - " +
                                    data.academicYear.to
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Class:</td>
                                <td>{{ data.class ? data.class.className : '' }}</td>
                              </tr>
                              <tr v-if="data.type == 'Contact'">
                                <td>Section:</td>
                                <td>{{ data.section.name }}</td>
                              </tr>
                              <tr v-if="data.type == 'Contact'">
                                <td>Admission Date:</td>
                                <td>{{ data.doj | dateFormat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
// import VueMonthlyPicker from "vue-monthly-picker";
// import NavBar from "@/views/ViNavBar.vue";

export default {
  name: "vi-parent-home",

  data() {
    return {
      showToast: false,
      count: {
        academicYear: 0,
        admission: 0,
        class: 0,
        departMent: 0,
        student: 0,
        teacher: 0,
      },
      studentInformation: [],
      onlineAdmissionList: [],
    };
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    getUserName() {
      let userData = secureUI.sessionGet("user");
      return (
        userData.userDetails.firstName + " " + userData.userDetails.lastName
      );
    },
  },
  created() {
    this.getStudentForParent();
    this.getOnlineAdmissionList();
  },
  methods: {
    redirectRoute(vipath) {
      this.showToast = true;
      if (this.$route.path !== vipath) {
        this.$router.push({ path: vipath });
      }
      this.showToast = false;
    },
    async getOnlineAdmissionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/admission/getParentOnlineAdmissionListView?parentId=${userData.userDetails._id}`,
          userData.token
        );
        if (response.isSuccess) {
          this.onlineAdmissionList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    viewStudentProfileAndPayemnt(studentId) {
      if (studentId) {
        this.$router.push(`/parent/student/portal/view/details/${studentId}`);
      }
    },
    viewStudentFreeSchool(studentId) {
      if (studentId) {
        this.$router.push(
          `/parent/student/freePortal/view/admission/details/${studentId}`
        );
      }
    },
  

    getStudentForParent() {
      let userData = secureUI.sessionGet("user");

      ViService.viXGet(
        "/contact/getStudentAndSchoolDetails/parent/portal",
        userData.token
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.studentInformation = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>